<template>
  <div class="Part">
    <div class='input'>
      <el-row>
        <el-button type="primary" size="mini">单个添加</el-button>&nbsp;
        <el-button type="primary" size="mini" @click="tianjiazixunButton">批量添加</el-button>&nbsp;
        <el-dialog title="批量添加房间" @close="dialogClose" :visible.sync="tianjiazixun" :close-on-click-modal="false" width="900px">
          <el-steps :active="modeForm.active" finish-status="success" simple style="margin-bottom:10px">
            <el-step title="1.设置参数" ></el-step>
            <el-step title="2.房间预设" ></el-step>
          </el-steps>
          <div v-show="modeForm.active==0">
            <el-button @click="addTemperas" icon="el-icon-edit" size="mini">新增规则</el-button>
            <el-button @click="delTemperas" icon="el-icon-edit" size="mini">删除规则</el-button>
            <el-button @click="saveTemperas" icon="el-icon-edit" size="mini">保存规则</el-button>
            <el-checkbox v-model="floorCheck">楼层小于10不补0</el-checkbox>
            <el-checkbox v-model="roomCheck">房号小于10不补0</el-checkbox>
            <!-- 内容表格 -->
            <el-table  :data="dataList4" @row-dblclick="dblclickShow" style="width:100%;">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="栋/单元" min-width="60px">
                <template slot-scope="scope">
                  <el-input v-model='scope.row.building' v-if='scope.row.inputShow' size="mini"></el-input>
                  <span v-else style="width:60px">{{scope.row.building}}</span>
                </template>
              </el-table-column>
              <el-table-column label="开始层数" min-width="60px">
                <template slot-scope="scope">
                  <el-input v-model='scope.row.startNumber' v-if='scope.row.inputShow' size="mini"></el-input>
                  <span v-else style="width:60px">{{scope.row.startNumber}}</span>
                </template>
              </el-table-column>
              <el-table-column label="结束层数" min-width="60px">
                <template slot-scope="scope">
                  <el-input v-model='scope.row.endNumber' v-if='scope.row.inputShow' size="mini"></el-input>
                  <span v-else style="width:60px">{{scope.row.endNumber}}</span>
                </template>
              </el-table-column>
              <el-table-column label="开始房号" min-width="60px">
                <template slot-scope="scope">
                  <el-input v-model='scope.row.startRoom' v-if='scope.row.inputShow' size="mini"></el-input>
                  <span v-else style="width:60px">{{scope.row.startRoom}}</span>
                </template>
              </el-table-column>
              <el-table-column label="结束房号" min-width="60px">
                <template slot-scope="scope">
                  <el-input v-model='scope.row.endRoom' v-if='scope.row.inputShow' size="mini"></el-input>
                  <span v-else style="width:60px">{{scope.row.endRoom}}</span>
                </template>
              </el-table-column>
              <el-table-column label="楼层前缀" min-width="60px">
                <template slot-scope="scope">
                  <el-input v-model='scope.row.floorPrefix' v-if='scope.row.inputShow' size="mini"></el-input>
                  <span v-else style="width:60px">{{scope.row.floorPrefix}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="fabutime2" label="房号前缀" min-width="60px">
                <template slot-scope="scope">
                  <el-input v-model='scope.row.roomNumberPrefix' v-if='scope.row.inputShow' size="mini"></el-input>
                  <span v-else style="width:60px">{{scope.row.roomNumberPrefix}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="fangan" label="生成方案" min-width="100px">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.roomPlan" v-if='scope.row.inputShow' size="mini">
                    <el-option v-for="(item,i) in roomPlanList" :key="i" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <span v-else style="width:60px">{{scope.row.roomPlan}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-show="modeForm.active==1">
            <!-- 内容表格 -->
            <el-table  v-loading="loading" :data="dataList5" @cell-click="cellClick" style="width:100%;">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="building" label="栋/单元" min-width="60px"></el-table-column>
              <el-table-column prop="floor" label="楼层" min-width="60px"></el-table-column>
              <el-table-column prop="doorplateno" label="门牌号" min-width="60px"></el-table-column>
              <el-table-column label="房间类型" min-width="60px">
                <template slot-scope="scope">
                  <span v-if="scope.row.selectShow">{{scope.row.hsAddCity}}</span>
                  <el-select v-else v-model="scope.row.hsAddCity" size="mini" @change="inputShowChange">
                    <el-option label="教室" value="教室"></el-option>
                    <el-option label="公区" value="公区"></el-option>
                    <el-option label="办公室" value="办公室"></el-option>
                  </el-select>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer">
            <el-button @click="tianjiazixun = false" v-show="modeForm.active == 0">取 消</el-button>
            <el-button type="primary" @click="tianjiazixunNext" v-show="modeForm.active == 0">下一步</el-button>
            <el-button @click="tianjiazixunLast" v-show="modeForm.active == 1">上一步</el-button>
            <el-button type="primary" @click="tianjiazixunButtonSave" v-show="modeForm.active == 1">保 存</el-button>
          </div>
        </el-dialog>
      </el-row><!--功能按钮-->
      <el-row style="padding: 5px 5px 5px 0;">
        门牌搜索：<el-input v-model="room.addressesSearch" @change="JobSearch" size="mini" clearable style="width:15%"></el-input>
        查询类型：<el-select v-model="room.roomType" @change="queryType" size="mini" style="width:200px" clearable>
        <el-option label="教室" value="教室"></el-option>
        <el-option label="公区" value="公区"></el-option>
        <el-option label="办公室" value="办公室"></el-option>
      </el-select>&nbsp;&nbsp;
      </el-row>
    </div>
    <!-- 内容表格 -->
    <el-table  v-loading="loading" :data="dataList3" highlight-current-row :height='tableHeight' style="width:100%;">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="hsAddBuilding" label="楼栋" min-width="100px"></el-table-column>
      <el-table-column prop="hsAddDoorplateno" label="门牌号" min-width="100px"></el-table-column>
      <el-table-column prop="hsAddCity" label="类型" min-width="100px"></el-table-column>
      <el-table-column prop="hsHouseNote" label="备注" min-width="180px"></el-table-column>
      <el-table-column prop="hsLeaseState" label="使用情况" min-width="100px"></el-table-column>
      <el-table-column prop="hsRegisterTime" label="添加时间" min-width="100px"></el-table-column>
    </el-table>
    <el-pagination
            background layout="total, sizes, prev, pager, next, jumper"
            align="center"
            :page-count="pages"
            :page-size="size"
            :total="total"
            :current-page="current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :page-sizes="[5,10,20,40]"
    ></el-pagination>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        tableHeight: window.innerHeight - 225,
        loading: false,
        tianjiazixun: false,
        roomPlanList:[{label:'1.数字楼层+数字房号',value:'1'},{label:'2.数字楼层+字母房号',value:'2'},{label:'3.楼层减一加A+数字房号',value:'3'},{label:'4.楼层减一加A+字母房号',value:'4'},{label:'5.楼层减一加A+数字房号减一加A',value:'5'},{label:'6.数字楼层+数字房号减一加A',value:'6'}],
        floorCheck:false,
        roomCheck:false,


        room:{
          addressesSearch: '',
          roomType: '教室',
        },

        modeForm:{
          active: 0,
        },

        total: 0,         //总条数
        size: 10,        //每页的条数
        pages: 0,      //每页数据
        current: 0,    //初始页

        dataList3: [],
        dataList4: [],
        dataList5: [],
        dataList6: [],
      }
    },
    created() {
      this.dataGet()
    },
    methods: {
      dataGet(){
        this.$axios({
          method: 'get',
          url: '/hotel/house4stores',
          params: {
            hsAddCity: this.room.roomType,
            hsAddDoorplateno: this.room.addressesSearch,
            cursor: this.current,
            limit:this.size
          }
        }).then(res=>{
          this.dataList3 = res.data.result.records
          var dataPage = res.data.result
          this.total=dataPage.total;
          this.size=dataPage.size;
          this.pages=dataPage.pages;
          this.current=dataPage.current;
        }).catch(err => {
          this.isError(err,this)
        })
      },
      JobSearch(){
        this.dataGet()
      },
      queryType(){
        this.dataGet()
      },
      handleCurrentChange(current) {
        this.current = current;
        this.dataGet()
      },
      handleSizeChange(size){
        this.size = size;
        this.dataGet()
      },
      tianjiazixunButton(){ //批量添加
        this.tianjiazixun = true
      },
      tianjiazixunNext(){
        let flag = this.dataList4.some(item=>{
          return item.inputShow == true
        })
        if(flag){
          this.$message.warning('请先保存规则')
        }else{
          this.modeForm.active = 1
          this.newCentralizedApartment()
        }
      },
      tianjiazixunLast(){
        this.modeForm.active = 0
      },
      dialogClose(){
        this.modeForm = {
          active: 0
        }
      },
      addTemperas(){//新增规则
        let obj = {
          inputShow: 1,//输入框显示
          building: '',//楼栋
          startNumber: '',//开始层数
          endNumber: '',//结束层数
          startRoom: '',//开始房间
          endRoom: '',//结束房间
          floorPrefix: '',//楼层前缀
          roomNumberPrefix: '',//房号前缀
          roomPlan: '',//房号命名方案
        }
        if(this.dataList4.length == 0){
          this.dataList4.push(obj)
        }else{
          let flag = this.dataList4.some(item=>{
            return item.inputShow == true
          })
          if(flag){
            this.$message.warning('请先保存规则')
          }else{
            this.dataList4.push(obj)
          }
        }
      },
      saveTemperas(){//保存规则
        if (this.dataList4.length == 0) {
          this.$message.warning('请添加规则')
        }
        for (let i in this.dataList4) {
          let building = this.dataList4[i].building;
          let floor = this.dataList4[i].startNumber;
          let floorNums = this.dataList4[i].endNumber - this.dataList4[i].startNumber + 1;
          let roomNums = this.dataList4[i].endRoom - this.dataList4[i].startRoom + 1;
          let namePlan = this.dataList4[i].roomPlan;
          // let floorNumPrefix = this.dataList4[i].startRoom;
          // let roomNumPrefix = this.dataList4[i].startFloor;
          // let inputShow=this.dataList4[i].inputShow
          if(!building || !floor || !this.dataList4[i].endNumber || !this.dataList4[i].endRoom || !this.dataList4[i].startRoom || !namePlan){
            this.$message.warning('请完善规则')
            return;
          }
          if(namePlan == 2 && roomNums > 26){
            this.$message.warning('字母房号每层最多26间')
            return;
          }
          if(namePlan == 4 && roomNums > 26){
            this.$message.warning('字母房号每层最多26间')
            return;
          }
          if(floorNums<1){
            this.$message.warning('楼层设置错误')
            return;
          }
          if(this.dataList4[i].startNumber==0){
            this.$message.warning('楼层不能等于0')
            return;
          }
          if(this.dataList4[i].startRoom==0){
            this.$message.warning('房间不能等于0')
            return;
          }
          if(this.dataList4[i].inputShow==1){
            this.dataList4[i].inputShow=0
          }
        }
        // console.log(this.dataList4)
        // for(let i = 0; i < this.dataList4.length; i++){
        //   this.dataList4[i].inputShow = 0
        // }
      },
      dblclickShow(row){//双击修改规则
        let flag = this.dataList4.some(item=>{
          return item.inputShow == 1
        })
        if(flag){
          this.$message.warning('请先保存规则')
        }else{
          row.inputShow = 1
        }
      },
      delTemperas(){
        let flag = this.dataList4.some(item=>{
          return item.inputShow == 1
        })
        if (this.dataList4.length == 0) {
          this.$message.warning('没有规则可删除')
        }else if(!flag){
          this.$message.warning('请双击选择规则')
        }
        for(let i=0;i<this.dataList4.length;i++){
          if(this.dataList4[i].inputShow==1){
            this.dataList4.splice(i,1)
          }
        }
      },
      newCentralizedApartment(){//生成规则
        if (this.dataList4.length == 0) {
          this.$message.warning('请添加规则')
          return;
        }
        let house = [];
        for (let i in this.dataList4) {
          let building = this.dataList4[i].building;
          let floor = this.dataList4[i].startNumber;
          let floorNums = this.dataList4[i].endNumber - this.dataList4[i].startNumber + 1;
          let roomNums = this.dataList4[i].endRoom - this.dataList4[i].startRoom + 1;
          let namePlan = this.dataList4[i].roomPlan;
          let floorNumPrefix = this.dataList4[i].floorPrefix;
          let roomNumPrefix = this.dataList4[i].roomNumberPrefix;
          if(namePlan == 2 && roomNums > 26){
            this.$message.warning('字母房号每层最多26间')
            return;
          }
          if(namePlan == 4 && roomNums > 26){
            this.$message.warning('字母房号每层最多26间')
            return;
          }
          if(floorNums<1){
            this.$message.warning('楼层设置错误')
            return;
          }
          let roomNumber = this.dataList4[i].startRoom
          let word = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          for(let j=1;j<parseInt(floorNums)*parseInt(roomNums)+1;j++){
            let item = {};
            item.building = building;
            item.floor = floor;
            if(roomNumber<10){
              item.roomNumber = "0" + roomNumber;
            }else{
              item.roomNumber = roomNumber;
            }
            item.floorNumPrefix = floorNumPrefix;
            item.roomNumPrefix = roomNumPrefix;
            if(namePlan==1){//数字楼层+数字房号
              let doorplateno = "";
              if(floorNumPrefix != "" && floorNumPrefix != null){
                doorplateno += floorNumPrefix;
              }
              if(floor<10){
                if(this.floorCheck){//选择后楼层小于10不补0
                  doorplateno += floor;
                }else{
                  doorplateno += "0" + floor;
                }
              }else{
                doorplateno += floor;
              }
              if(roomNumPrefix != "" && roomNumPrefix != null){
                doorplateno += roomNumPrefix;
              }
              if(roomNumber<10){
                if(this.roomCheck){//选择后房号小于10不补0
                  doorplateno += roomNumber;
                }else{
                  doorplateno += "0" + roomNumber;
                }
              }else{
                doorplateno += roomNumber;
              }
              item.doorplateno = doorplateno;
            }
            if(namePlan==2){//数字楼层+字母房号
              let doorplateno = "";
              if(floorNumPrefix != "" && floorNumPrefix != null){
                doorplateno += floorNumPrefix;
              }
              if(floor<10){
                if(this.floorCheck){
                  doorplateno += floor;
                }else{
                  doorplateno += "0" + floor;
                }
              }else{
                doorplateno += floor;
              }
              if(roomNumPrefix != "" && roomNumPrefix != null){
                doorplateno += roomNumPrefix;
              }
              doorplateno += word[parseInt(roomNumber) - 1];
              item.doorplateno = doorplateno;
            }
            if(namePlan == 3){//楼层减一加A，数字房号
              let doorplateno = "";
              if(floorNumPrefix != "" && floorNumPrefix != null){
                doorplateno += floorNumPrefix;
              }
              let floor2 = floor - 1;
              if(floor2<10){
                if(this.floorCheck){
                  doorplateno += floor2 + "A";
                }else{
                  doorplateno += "0" + floor2 + "A";
                }
              }else{
                doorplateno += floor2 + "A";
              }
              if(roomNumPrefix != "" && roomNumPrefix != null){
                doorplateno += roomNumPrefix;
              }
              if(roomNumber<10){
                if(this.roomCheck){//选择后房号小于10不补0
                  doorplateno += roomNumber;
                }else{
                  doorplateno += "0" + roomNumber;
                }
              }else{
                doorplateno += roomNumber;
              }
              item.doorplateno = doorplateno;
            }
            if(namePlan == 4){//楼层减一加A，字母房号
              let doorplateno = "";
              if(floorNumPrefix != "" && floorNumPrefix != null){
                doorplateno += floorNumPrefix;
              }
              let floor2 = floor - 1;
              if(floor2<10){
                if(this.floorCheck){
                  doorplateno += floor2 + "A";
                }else{
                  doorplateno += "0" + floor2 + "A";
                }
              }else{
                doorplateno += floor2 + "A";
              }
              if(roomNumPrefix != "" && roomNumPrefix != null){
                doorplateno += roomNumPrefix;
              }
              doorplateno += word[parseInt(roomNumber) - 1];
              item.doorplateno = doorplateno;
            }
            if(namePlan == 5){//楼层减一加A+数字房号减一加A
              let doorplateno = "";
              if(floorNumPrefix != "" && floorNumPrefix != null){
                doorplateno += floorNumPrefix;
              }
              let floor2 = floor - 1;
              if(floor2<10){
                if(this.floorCheck){
                  doorplateno += floor2 + "A";
                }else{
                  doorplateno += "0" + floor2 + "A";
                }
              }else{
                doorplateno += floor2 + "A";
              }
              if(roomNumPrefix != "" && roomNumPrefix != null){
                doorplateno += roomNumPrefix;
              }
              let room2 = roomNumber - 1;
              if(room2<10){
                if(this.roomCheck){//选择后房号小于10不补0
                  doorplateno += room2 + "A";
                }else{
                  doorplateno += "0" + room2 + "A";
                }
              }else{
                doorplateno += room2 + "A";
              }
              item.doorplateno = doorplateno;
            }
            if(namePlan == 6){//数字楼层+数字房号减一加A
              let doorplateno = "";
              if(floorNumPrefix != "" && floorNumPrefix != null){
                doorplateno += floorNumPrefix;
              }
              if(floor<10){
                if(this.floorCheck){
                  doorplateno += floor;
                }else{
                  doorplateno += "0" + floor;
                }
              }else{
                doorplateno += floor;
              }
              if(roomNumPrefix != "" && roomNumPrefix != null){
                doorplateno += roomNumPrefix;
              }
              let room2 = roomNumber - 1;
              if(room2<10){
                if(this.roomCheck){//选择后房号小于10不补0
                  doorplateno += room2 + "A";
                }else{
                  doorplateno += "0" + room2 + "A";
                }
              }else{
                doorplateno += room2 + "A";
              }
              item.doorplateno = doorplateno;
            }
            item.hsAddCity = '教室'
            item.selectShow = true
            house.push(item);
            roomNumber++;
            if(roomNumber>this.dataList4[i].endRoomNum){
              roomNumber=this.dataList4[i].startRoomNum;
              floor++;
            }
          }
        }
        for(let i in house){
          this.dataList6[i] = {hsAddBuilding : house[i].building, hsAddCity: house[i].hsAddCity,
          hsAddDoorplateno: house[i].doorplateno, hsAddFloor: house[i].floor,selectShow: house[i].selectShow}
        }
        this.dataList5 = house
        console.log(this.dataList5)
        console.log(this.dataList6)
      },
      cellClick(row,column){//单击改变房间类型
        if(column.label === '房间类型'){
          for(let i of this.dataList5){
            i.selectShow = true
          }
          row.selectShow = false
        }else{
          for(let i of this.dataList5){
            i.selectShow = true
          }
          row.selectShow = true
        }
      },
      inputShowChange(){
        for(let i of this.dataList5){
          i.selectShow = true
        }
      },
      tianjiazixunButtonSave(){//保存批量添加
        console.log(JSON.stringify(this.dataList5))
        this.loading = true
          this.$axios({
            method: 'post',
            url: '/hotel/house4stores',
            data:this.dataList6
          }).then(res => {
            this.$message.success('添加成功')
            this.dataGet()
            this.loading = false
          }).catch(err => {
            this.loading = false
            this.isError(err,this)
          })
        this.tianjiazixun = false
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>
